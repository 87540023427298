
function InvoiceContent({ products, customerName, customerAddress, customerPhone, date  }) {
  const productRows = products.map((product, index) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{product.name}</td>
      <td>{product.quantity}</td>
      <td>{product.price.toFixed(2)}</td>
      <td>{(product.quantity * product.price).toFixed(2)}</td>
    </tr>
  ));

  const totalPrice = products.reduce(
    (total, product) => total + product.quantity * product.price,
    0
  );

  return (
    <div>
      <div className="barnd">
        <h3>SR CONFIDENCE ENGINEERING SDN. BHD.</h3>
        <p><span>Address:</span>No. 10A Jalan 6/3, Pandan Indah commercial Park, 55100 Ampang, Kuala Lumpur </p>
         <p><span>Tel/Whatsapp:</span>+601151510113, +60199655465</p>
      </div>
      <div className="line"></div>

      <div className="title"><p>Quotation</p></div>
      <div className="info">
         <b><p>TARIKH : {date}</p></b> 
        <p>KEPADA: {customerName}</p>
        <p>LOKASI: {customerAddress}</p>
        <p>PHONE: {customerPhone}</p>
     
      <table > {/* Add class name for the table */}
        <thead>
          <tr>
            <th>QTY.</th> {/* Add class name for table header */}
            <th>ITEM/DESCRIPTION</th>
            <th>TOTAL RM SQft/UNIT</th>
            <th>PRICE PER UNIT</th>
            <th>TOTAL RM</th>
          </tr>
        </thead>
        <tbody>{productRows}</tbody>
      </table>
      <div className="total">
         <b><p>TOTAL: {totalPrice.toFixed(2)} RM</p></b> 
      </div>
      <div className="footer">
      <b>Bank akaun nombor:</b>
      <p>SR CONFIDENCE ENGINEERING SDN. BHD.<br/>
        26211100020738<br/>
        RHB ISLAMIC BANK</p>
      </div>
    </div>

    </div>
  );
}

export default InvoiceContent;

