import React, { useState } from 'react';
import InvoiceContent from './InvoiceContent';
import { renderToString } from 'react-dom/server';

function App() {
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
  const [products, setProducts] = useState([]);
  const [productName, setProductName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [editProductIndex, setEditProductIndex] = useState(null);

  const addProduct = () => {
    if (productName && quantity > 0 && price > 0) {
      const newProduct = {
        name: productName,
        quantity: quantity,
        price: price,
      };
      setProducts([...products, newProduct]);
      setProductName('');
      setQuantity('');
      setPrice('');
    }
  };

  const deleteProduct = (index) => {
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);
  };

  const editProduct = (index) => {
    setEditProductIndex(index);
    const productToEdit = products[index];
    setProductName(productToEdit.name);
    setQuantity(productToEdit.quantity);
    setPrice(productToEdit.price);
  };

  const saveEditedProduct = () => {
    if (productName && quantity > 0 && price > 0 && editProductIndex !== null) {
      const updatedProducts = [...products];
      updatedProducts[editProductIndex] = {
        name: productName,
        quantity: quantity,
        price: price,
      };
      setProducts(updatedProducts);
      resetEditState();
    }
  };

  const resetEditState = () => {
    setEditProductIndex(null);
    setProductName('');
    setQuantity('');
    setPrice('');
  };

  const calculateTotalQuantity = () => {
    return products.reduce((total, product) => total + product.quantity, 0);
  };

  const calculateTotalPrice = () => {
    return products.reduce((total, product) => total + product.quantity * product.price, 0);
  };

  const handlePrint = () => {
    const printWindow = window.open('', '', 'width=600,height=800');
    const customInvoiceContent = renderToString(
      <InvoiceContent
        products={products}
        customerName={customerName}
        customerAddress={customerAddress}
        customerPhone={customerPhone}
        date={formattedDate}
      />
    );
    const printDocument = `
    <html>
      <head>
        <link rel="stylesheet" type="text/css" href="print.css" media="screen">
        <link rel="stylesheet" type="text/css" href="print1.css" media="print">
      </head>
      <body>
        ${customInvoiceContent}
      </body>
    </html>
  `;

    printWindow.document.open();
    printWindow.document.write(printDocument);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className="container">
      <div className='brand'>
        <h3>hello</h3>
      </div>
      <div className='row'>
        <div className='col'>
          <h3>Customer Information</h3>
          <input
            type="text"
            placeholder="Customer Name"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Customer Address"
            value={customerAddress}
            onChange={(e) => setCustomerAddress(e.target.value)}
          />
          <input
            type="text"
            placeholder="Customer Phone"
            value={customerPhone}
            onChange={(e) => setCustomerPhone(e.target.value)}
          />

          <h3>Add Product</h3>
          <input
            type="text"
            placeholder="Product Name"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
          />
          <p>QTY.</p>
          <input
            type="number"
            placeholder="Quantity"
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
          />
          <p>Price</p>
          <input
            type="number"
            placeholder="Price"
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
          />
          {editProductIndex !== null ? (
            <>
              <button className="btn" onClick={saveEditedProduct}>
                Save
              </button>
              <button className="btn" onClick={resetEditState}>
                Cancel
              </button>
            </>
          ) : (
            <button className="btn" onClick={addProduct}>
              Add
            </button>
          )}
        </div>
        <div className='col'>
          <h3>Invoice</h3>
          <div className='table-container'>
            <table>
              <thead>
                <tr>
                  <th>QTY</th>
                  <th>ITEM/DESCRIPTION</th>
                  <th>Total RM SQft/UNIT</th>
                  <th>TOTAL RM</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => (
                  <tr key={index}>
                    <td>{`${product.quantity}`}</td>
                    <td>{product.name}</td>
                    <td>{`${product.price.toFixed(2)} X ${product.quantity}`}</td>
                    <td>{(product.quantity * product.price).toFixed(2)}</td>
                    <td>
                      <button className="btn-del" onClick={() => deleteProduct(index)}>
                      <i class="fas fa-trash"></i>
                      </button>
                      <button className="btn-edit"  onClick={() => editProduct(index)}>
                      <i class="fas fa-edit"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='total'>
            <p>Total Quantity: {calculateTotalQuantity()}</p>
            <p>Total Price: RM {calculateTotalPrice().toFixed(2)}</p>
          </div>
          <button className="btn" onClick={handlePrint}>
            Print Invoice
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
